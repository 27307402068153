import { useState, useEffect } from 'react';

interface UserLocation {
  latitude: number;
  longitude: number;
  error?: string;
}

export function useUserLocation() {
  const [location, setLocation] = useState<UserLocation | null>(null);

  useEffect(() => {
    if (!navigator.geolocation) {
      setLocation({ latitude: 0, longitude: 0, error: 'Geolocation is not supported' });
      return;
    }

    const watchId = navigator.geolocation.watchPosition(
      (position) => {
        setLocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude
        });
      },
      (error) => {
        setLocation({ latitude: 0, longitude: 0, error: error.message });
      },
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      }
    );

    return () => navigator.geolocation.clearWatch(watchId);
  }, []);

  return location;
}