import { Vehicle } from '../types';
import { isVehicleActive } from '../utils/vehicleUtils';
import { FrontBus, FrontCar } from './VehicleIcons';

interface VehicleMarkerProps {
  vehicle: Vehicle;
}

export default function VehicleMarker({ vehicle }: VehicleMarkerProps) {
  const lastUpdateTime = new Date(vehicle.lastUpdate).toLocaleTimeString();
  const VehicleIcon = vehicle.type === 'golf-cart' ? FrontCar : FrontBus;
  const isActive = isVehicleActive(vehicle.lastUpdate);

  return (
    <div className="relative group cursor-pointer">
      <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full 
                    bg-white rounded-lg px-4 py-2 shadow-lg
                    opacity-0 group-hover:opacity-100 transition-all duration-200 min-w-[200px]
                    pointer-events-none z-[9999]">
        <div className="space-y-1">
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Vehicle:</span> {vehicle.name}
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Route:</span> {vehicle.route}
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Plate:</span> {vehicle.plate}
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Speed:</span> {vehicle.mph} MPH
          </p>
          <p className="text-sm font-medium text-gray-900">
            <span className="font-semibold">Last Update:</span> {lastUpdateTime}
          </p>
          {!isActive && (
            <p className="text-sm font-medium text-red-600">
              Vehicle Currently Inactive
            </p>
          )}
        </div>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 
                      rotate-45 w-2 h-2 bg-white"></div>
      </div>
      <div className="relative">
        <div className="absolute -inset-2 bg-black/5 rounded-full blur-sm group-hover:bg-black/10 
                      transition-all duration-200"></div>
        <VehicleIcon 
          className="h-10 w-10 transition-transform duration-500 group-hover:scale-110"
          style={{ color: isActive ? vehicle.color : '#9CA3AF' }}
        />
      </div>
    </div>
  );
}