import { Clock, X, ChevronUp, ChevronDown } from 'lucide-react';
import { Vehicle } from '../types';
import { isVehicleActive } from '../utils/vehicleUtils';
import { useState } from 'react';
import ServiceInfo from './ServiceInfo';
import { FrontBus, FrontCar } from './VehicleIcons';

interface VehicleListProps {
  vehicles: Vehicle[];
}

export default function VehicleList({ vehicles }: VehicleListProps) {
  const [showServiceInfo, setShowServiceInfo] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const activeVehicles = vehicles.filter(v => isVehicleActive(v.lastUpdate));
  const inactiveVehicles = vehicles.filter(v => !isVehicleActive(v.lastUpdate));

  const VehicleGroup = ({ vehicles, title }: { vehicles: Vehicle[], title: string }) => (
    <div className="space-y-2">
      <h4 className="text-xs font-medium text-gray-500 uppercase tracking-wider">{title}</h4>
      {vehicles.map((vehicle) => {
        const VehicleIcon = vehicle.type === 'golf-cart' ? FrontCar : FrontBus;
        const isActive = isVehicleActive(vehicle.lastUpdate);
        
        return (
          <div key={vehicle.id} className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <VehicleIcon 
                className="h-4 w-4" 
                style={{ color: isActive ? vehicle.color : '#9CA3AF' }} 
              />
              <div className="flex flex-col">
                <span className={`text-sm ${isActive ? 'text-gray-700' : 'text-gray-400'}`}>
                  {vehicle.name}
                </span>
                <span className={`text-xs ${isActive ? 'text-gray-500' : 'text-gray-400'}`}>
                  {vehicle.plate}
                </span>
              </div>
            </div>
            <span className={`text-xs ${isActive ? 'text-gray-500' : 'text-gray-400'}`}>
              {vehicle.mph} MPH
            </span>
          </div>
        );
      })}
    </div>
  );

  return (
    <>
      {/* Mobile Toggle Button */}
      <button
        onClick={() => setIsExpanded(!isExpanded)}
        className="fixed bottom-4 left-4 md:hidden z-50 bg-white rounded-full p-3 shadow-lg"
      >
        {isExpanded ? (
          <ChevronDown className="h-6 w-6 text-gray-600" />
        ) : (
          <ChevronUp className="h-6 w-6 text-gray-600" />
        )}
      </button>

      {/* Vehicle Status Panel */}
      <div className={`
        fixed md:absolute 
        bottom-0 md:bottom-4 
        left-0 md:left-4 
        w-full md:w-auto 
        md:min-w-[280px]
        max-w-full md:max-w-xs
        bg-white 
        rounded-t-lg md:rounded-lg 
        shadow-lg 
        transition-transform duration-300 ease-in-out
        ${isExpanded ? 'translate-y-0' : 'translate-y-[calc(100%-3.5rem)]'} 
        md:translate-y-0
      `}>
        <div className="p-4">
          <div className="flex items-center justify-between mb-4">
            {/* Mobile Handle */}
            <div className="md:hidden w-12 h-1 bg-gray-300 rounded-full mx-auto absolute top-2 left-1/2 transform -translate-x-1/2" />
            
            <h3 className="text-sm font-semibold text-gray-900 mt-4 md:mt-0">Vehicles Status</h3>
            <Clock 
              className="h-5 w-5 text-gray-500 cursor-pointer hover:text-gray-700 transition-colors"
              onClick={() => setShowServiceInfo(true)}
            />
          </div>
          
          <div className="space-y-4">
            {activeVehicles.length > 0 && (
              <VehicleGroup vehicles={activeVehicles} title="Active" />
            )}
            
            {inactiveVehicles.length > 0 && (
              <VehicleGroup vehicles={inactiveVehicles} title="Inactive" />
            )}
          </div>
        </div>
      </div>

      {/* Service Info Modal */}
      {showServiceInfo && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[60] p-4">
          <div className="relative bg-white rounded-lg shadow-xl max-w-2xl w-full max-h-[90vh] overflow-y-auto">
            <button
              onClick={() => setShowServiceInfo(false)}
              className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
            >
              <X className="h-6 w-6 text-gray-600" />
            </button>
            <ServiceInfo />
          </div>
        </div>
      )}
    </>
  );
}