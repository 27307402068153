import { SVGProps } from 'react';

export function FrontBus(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      {/* Main body - taller and more van-like */}
      <path d="M4 6a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6z" />
      
      {/* Windshield - larger and more angled */}
      <path d="M6 6h12v6H6z" />
      
      {/* Hood */}
      <path d="M5 8h14" />
      
      {/* Headlights */}
      <circle cx="7" cy="10" r="1" />
      <circle cx="17" cy="10" r="1" />
      
      {/* Wheels */}
      <circle cx="8" cy="18" r="2" />
      <circle cx="16" cy="18" r="2" />
      
      {/* Grill */}
      <path d="M8 13h8" />
      <path d="M8 15h8" />
      
      {/* Side mirrors */}
      <path d="M3 10h1" />
      <path d="M20 10h1" />
    </svg>
  );
}

export function FrontCar(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M7 17h10M6 11V6a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v5" />
      <path d="M5 11h14a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2z" />
      <circle cx="7" cy="17" r="2" />
      <circle cx="17" cy="17" r="2" />
    </svg>
  );
}