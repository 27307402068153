import Map, { Marker, NavigationControl } from 'react-map-gl';
import VehicleMarker from './VehicleMarker';
import VehicleList from './VehicleList';
import BusStopMarker from './BusStopMarker';
import UserLocationMarker from './UserLocationMarker';
import { useVehicles } from '../hooks/useVehicles';
import { useUserLocation } from '../hooks/useUserLocation';
import { busStops } from '../data/busStops';
import 'mapbox-gl/dist/mapbox-gl.css';

export default function MapComponent() {
  const { vehicles, error, isLoading } = useVehicles();
  const userLocation = useUserLocation();

  return (
    <div className="flex-1 relative mt-14">
      {error && (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-50
                      bg-red-100 border border-red-400 text-red-700 px-4 py-2 rounded-lg">
          {error}
        </div>
      )}
      {isLoading && (
        <div className="absolute top-4 left-1/2 transform -translate-x-1/2 z-50
                      bg-blue-100 border border-blue-400 text-blue-700 px-4 py-2 rounded-lg">
          Loading vehicle locations...
        </div>
      )}
      <Map
        mapboxAccessToken="pk.eyJ1IjoiYWxpZGFubyIsImEiOiJjbTNpc3J6am0wNGE5MmxxMjBsZG52N2VnIn0.dzLrPM7LVTOiZQ5_ThPgvg"
        initialViewState={{
          longitude: -66.1155,
          latitude: 18.4655,
          zoom: 15,
          pitch: 45
        }}
        style={{ width: '100%', height: '100%' }}
        mapStyle="mapbox://styles/mapbox/streets-v12"
      >
        <NavigationControl position="bottom-right" />
        
        {/* Bus Stops */}
        {busStops.map((stop) => (
          <Marker
            key={stop.id}
            longitude={stop.location[1]}
            latitude={stop.location[0]}
            anchor="bottom"
          >
            <BusStopMarker stop={stop} />
          </Marker>
        ))}

        {/* Vehicles */}
        {vehicles.map((vehicle) => (
          <Marker
            key={vehicle.id}
            longitude={vehicle.lng}
            latitude={vehicle.lat}
            anchor="center"
          >
            <VehicleMarker vehicle={vehicle} />
          </Marker>
        ))}

        {/* User Location */}
        {userLocation && !userLocation.error && (
          <Marker
            longitude={userLocation.longitude}
            latitude={userLocation.latitude}
            anchor="bottom"
          >
            <UserLocationMarker />
          </Marker>
        )}
      </Map>
      <VehicleList vehicles={vehicles} />
    </div>
  );
}