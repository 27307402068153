import { MapPin } from 'lucide-react';

export default function UserLocationMarker() {
  return (
    <div className="relative">
      <div className="absolute -top-[28px] -left-[12px]">
        <MapPin 
          className="h-6 w-6 text-red-600 animate-bounce"
          strokeWidth={3}
        />
      </div>
    </div>
  );
}