import { useState } from 'react';
import { Menu, X, Clock } from 'lucide-react';
import ServiceInfo from './ServiceInfo';

export default function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showServiceInfo, setShowServiceInfo] = useState(false);

  return (
    <>
      <nav className="fixed top-0 w-full bg-white/90 backdrop-blur-sm shadow-lg z-50">
        <div className="container mx-auto px-4 py-2">
          <div className="flex items-center justify-between">
            <button
              onClick={() => setIsMenuOpen(!isMenuOpen)}
              className="p-2 rounded-lg hover:bg-gray-100 transition-colors duration-200"
            >
              {isMenuOpen ? (
                <X className="h-6 w-6 text-gray-600" />
              ) : (
                <Menu className="h-6 w-6 text-gray-600" />
              )}
            </button>

            <img 
              src="https://1drv.ms/i/s!AkVjizmWhbX8ntYA2e_fYtOnuX8ZZA?embed=1&width=962&height=172" 
              alt="CarTracker Logo" 
              className="h-[60px] w-auto object-contain"
            />

            <div className="w-10" />
          </div>

          {/* Slide-out Menu */}
          <div className={`
            absolute left-0 top-full w-64 bg-white shadow-lg rounded-b-lg transform transition-transform duration-200 ease-in-out
            ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'}
          `}>
            <div className="p-4 space-y-2">
              <button 
                onClick={() => {
                  setShowServiceInfo(true);
                  setIsMenuOpen(false);
                }}
                className="flex items-center space-x-2 w-full px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
              >
                <Clock className="h-5 w-5" />
                <span>Horarios de Servicio</span>
              </button>
              
              <a 
                href="https://www.codevisa.org" 
                target="_blank" 
                rel="noopener noreferrer"
                className="flex items-center space-x-2 w-full px-4 py-2 text-gray-700 hover:bg-gray-100 rounded-lg transition-colors duration-200"
              >
                <span>Visitar Codevisa</span>
              </a>
            </div>
          </div>
        </div>
      </nav>

      {/* Service Info Modal */}
      {showServiceInfo && (
        <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-[60] p-4">
          <div className="relative max-h-[90vh] overflow-y-auto">
            <button
              onClick={() => setShowServiceInfo(false)}
              className="absolute top-2 right-2 p-2 rounded-full hover:bg-gray-100 transition-colors duration-200"
            >
              <X className="h-6 w-6 text-gray-600" />
            </button>
            <ServiceInfo />
          </div>
        </div>
      )}
    </>
  );
}