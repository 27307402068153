export interface BusStop {
  id: string;
  name: string;
  location: [number, number];
}

export const busStops: BusStop[] = [
  {
    id: "stop-1",
    name: "Casa Alcaldía",
    location: [18.4654411, -66.1167691]
  },
  {
    id: "stop-2",
    name: "Hotel Plaza de Armas",
    location: [18.4648690, -66.1170544]
  },
  {
    id: "stop-3a",
    name: "Paseo La Princesa",
    location: [18.463346, -66.1175194]
  },
  {
    id: "stop-3b",
    name: "Entrada Paseo de la Princesa",
    location: [18.4636126, -66.115757468]
  },
  {
    id: "stop-4",
    name: "Terminal de Lancha",
    location: [18.4634208, -66.1132218]
  },
  {
    id: "stop-5",
    name: "Don Rey Cigar",
    location: [18.4643345, -66.1110254]
  },
  {
    id: "stop-6",
    name: "Esquina Covadonga",
    location: [18.4652160, -66.1111626]
  },
  {
    id: "stop-7",
    name: "Recinto Sur",
    location: [18.4646957, -66.1130636]
  },
  {
    id: "stop-8",
    name: "EcuaBori Souvenirs",
    location: [18.4644687, -66.1141033]
  },
  {
    id: "stop-9",
    name: "La Tanca",
    location: [18.4659051, -66.1142082]
  },
  {
    id: "stop-10",
    name: "Teatro Tapia",
    location: [18.4657884, -66.1121540]
  },
  {
    id: "stop-11a",
    name: "Castillo San Cristóbal",
    location: [18.4676129, -66.1121687]
  },
  {
    id: "stop-11b",
    name: "Entrada Castillo",
    location: [18.4675861, -66.1120668]
  },
  {
    id: "stop-12",
    name: "La Vergüenza",
    location: [18.4679471, -66.1144111]
  },
  {
    id: "stop-13a",
    name: "El Morro Entrada",
    location: [18.4683386, -66.1210207]
  },
  {
    id: "stop-13b",
    name: "El Morro Paseo",
    location: [18.4683246, -66.1211511]
  },
  {
    id: "stop-13c",
    name: "Escuela de Artes Plásticas",
    location: [18.4681913, -66.1211357]
  },
  {
    id: "stop-14",
    name: "Liga de Arte",
    location: [18.4674526, -66.1190110]
  },
  {
    id: "stop-15",
    name: "La Fortaleza",
    location: [18.4648205, -66.116243]
  },
  {
    id: "stop-16",
    name: "Plaza Colón",
    location: [18.4661020, -66.1118881]
  },
  {
    id: "stop-17",
    name: "Plaza San Juan Bautista",
    location: [18.4674163, -66.1059993]
  },
  {
    id: "stop-18a",
    name: "Paseo de los Presidentes",
    location: [18.4662241, -66.105912]
  },
  {
    id: "stop-18b",
    name: "Monumento del Holocausto",
    location: [18.4661834, -66.1064084]
  },
  {
    id: "stop-19",
    name: "Tostado",
    location: [18.4656062, -66.1011723]
  },
  {
    id: "stop-20",
    name: "Teatro Silvia Rexach",
    location: [18.4650020, -66.0967071]
  },
  {
    id: "stop-21",
    name: "Terraza del Mar",
    location: [18.4659522, -66.0990132]
  }
];