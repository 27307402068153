import { Clock, Phone, Mail, Globe } from 'lucide-react';

export default function ServiceInfo() {
  return (
    <div className="bg-white rounded-lg shadow-lg p-6 max-w-2xl mx-auto">
      <h2 className="text-2xl font-bold text-gray-900 mb-6">Horarios de Servicio</h2>
      
      <div className="space-y-6">
        <p className="text-gray-700 leading-relaxed">
          Luego de casi 40 años, CODEVISA continúa siendo tu opción para viajar a todos los barrios 
          de la Isleta de San Juan. Nuestro horario de viajes comienza a las 8am hasta las 8pm de 
          lunes a Viernes y sábados de 8am a 4pm.
        </p>
        
        <p className="text-gray-700 leading-relaxed">
          En CODEVISA nos dedicamos a ofrecerte la mejor experiencia posible de movilidad dentro 
          de Isleta de San Juan.
        </p>
        
        <p className="text-gray-700 font-medium">
          ¡Gracias por confiar en nosotros!
        </p>
        
        <div className="border-t pt-6">
          <h3 className="text-lg font-semibold text-gray-900 mb-4">Para más información:</h3>
          
          <div className="space-y-3">
            <div className="flex items-start space-x-3">
              <Clock className="h-5 w-5 text-gray-500 mt-1" />
              <div>
                <p className="text-gray-700">Lunes a viernes de 8am a 4pm</p>
                <div className="flex items-center space-x-2">
                  <Phone className="h-4 w-4 text-gray-500" />
                  <p className="text-gray-700">(787) 723-7080</p>
                </div>
              </div>
            </div>
            
            <div className="flex items-start space-x-3">
              <Clock className="h-5 w-5 text-gray-500 mt-1" />
              <div>
                <p className="text-gray-700">Lunes a viernes de 4pm a 8pm y sábados de 8am a 4pm</p>
                <div className="flex items-center space-x-2">
                  <Phone className="h-4 w-4 text-gray-500" />
                  <p className="text-gray-700">787-414-3535 / 787-370-5204</p>
                </div>
              </div>
            </div>
            
            <div className="flex items-center space-x-3">
              <Mail className="h-5 w-5 text-gray-500" />
              <a href="mailto:admin@codevisa.org" className="text-blue-600 hover:underline">
                admin@codevisa.org
              </a>
            </div>
            
            <div className="flex items-center space-x-3">
              <Globe className="h-5 w-5 text-gray-500" />
              <a href="http://www.codevisa.org" target="_blank" rel="noopener noreferrer" 
                 className="text-blue-600 hover:underline">
                www.codevisa.org
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}