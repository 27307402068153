import { useState, useCallback, useEffect } from 'react';
import { Vehicle, VehicleLocation } from '../types';
import { isVehicleActive } from '../utils/vehicleUtils';

const VEHICLES_INFO = {
  '864035052382927': {
    route: 'Old San Juan Express',
    name: 'Ford Transit 821',
    color: '#2563eb', // Blue for vans
    plate: 'KFW821',
    type: 'van',
    serialNumber: '1FBVU4XG9PK75306'
  },
  '864035052792497': {
    route: 'Condado Shuttle',
    name: 'Chevrolet Express 711',
    color: '#2563eb', // Blue for vans
    plate: 'KGS711',
    type: 'van',
    serialNumber: '1GAZGNFP9P1181388'
  },
  '864035051785161': {
    route: 'Plaza Route',
    name: 'Garita 818',
    color: '#16a34a', // Green for golf carts
    plate: 'JSB818',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CACG3199247'
  },
  '864035052792299': {
    route: 'Cathedral Circuit',
    name: 'Garita 111',
    color: '#16a34a', // Green for golf carts
    plate: 'IGG111',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CAVJ3361751'
  },
  '864035052374221': {
    route: 'Castillo Express',
    name: 'Garita 110',
    color: '#16a34a', // Green for golf carts
    plate: 'IGG110',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CACJ3361750'
  },
  '864035052792398': {
    route: 'Old San Juan Historic Loop',
    name: 'Garita 109',
    color: '#16a34a', // Green for golf carts
    plate: 'IGG109',
    type: 'golf-cart',
    serialNumber: 'EZGBY0CAEEJ3365160'
  }
};

export function useVehicles() {
  const [vehicles, setVehicles] = useState<Vehicle[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchVehicleLocations = useCallback(async () => {
    try {
      const response = await fetch('https://iml57oxpa4.execute-api.us-east-1.amazonaws.com/v3/location', {
        method: 'GET',
        headers: {
          'Accept': 'application/json',
          'Origin': window.location.origin
        }
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();
      
      if (data.statusCode === 200 && Array.isArray(data.body)) {
        const updatedVehicles = data.body
          .filter((location: VehicleLocation) => VEHICLES_INFO[location.carId])
          .map((location: VehicleLocation) => {
            const active = isVehicleActive(location.event_time);
            return {
              id: location.carId,
              lat: location.lat,
              lng: location.lon,
              mph: active ? location.mph : 0, // Force speed to 0 for inactive vehicles
              head: location.head,
              lastUpdate: location.event_time,
              ...VEHICLES_INFO[location.carId]
            };
          });
        
        setVehicles(updatedVehicles);
        setError(null);
      } else {
        throw new Error('Invalid data format received from API');
      }
    } catch (err) {
      setError('Unable to fetch vehicle locations. Please try again later.');
      console.error('Error fetching vehicle locations:', err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchVehicleLocations();
    const interval = setInterval(fetchVehicleLocations, 500); // Update every 500ms
    return () => clearInterval(interval);
  }, [fetchVehicleLocations]);

  return { vehicles, error, isLoading };
}