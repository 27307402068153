import { CircleDot } from 'lucide-react';
import { BusStop } from '../data/busStops';

interface BusStopMarkerProps {
  stop: BusStop;
}

export default function BusStopMarker({ stop }: BusStopMarkerProps) {
  return (
    <div className="relative group cursor-pointer">
      <div className="absolute -top-2 left-1/2 transform -translate-x-1/2 -translate-y-full 
                    bg-white rounded-lg px-4 py-2 shadow-lg
                    opacity-0 group-hover:opacity-100 transition-all duration-200
                    pointer-events-none z-[9999]">
        <p className="text-sm font-semibold text-gray-900 whitespace-nowrap">{stop.name}</p>
        <div className="absolute bottom-0 left-1/2 transform -translate-x-1/2 translate-y-1/2 
                      rotate-45 w-2 h-2 bg-white"></div>
      </div>
      <div className="bg-white rounded-full p-1 shadow-lg">
        <CircleDot 
          className="h-5 w-5 transition-transform duration-200 group-hover:scale-110"
          style={{ color: '#EAB308' }}
        />
      </div>
    </div>
  );
}