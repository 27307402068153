import Navbar from './components/Navbar';
import MapComponent from './components/Map';

function App() {
  return (
    <div className="h-screen flex flex-col">
      <Navbar />
      <MapComponent />
    </div>
  );
}

export default App;